import React from 'react';
import Layout from '../../layouts/index';
import { Link } from "gatsby";


class casestudies extends React.Component {
    render() {
        return (
            <Layout bodyClass="page-casestudies">
                <div className="intro pt-6 pt-sm-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1>Case Studies</h1>
                            </div>
                        </div>
                        <hr className="section-divider mt-sm-7 mb-4 mb-sm-9" />
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        {/* } <div className="col-12 col-md-6 mb-4">
                            <div className="case">
                                <div className="card-content">
                                    <Link to="/casestudies/enhancing-the-supply-chain-in-premium-beef-production/"><h4>Enhancing the Supply-Chain in Premium Beef Production</h4></Link>
                                    <p className="truncate"> An Australian cattle intermediary, producing a
                                    unique high-end variety of meat to restaurants
                                    within Australia and overseas. Lilardia Capital's solution was deployed to digitise the supply-chain process, helping 
                                    the customer to comply with regulatory requirements, and ensuring that each animal was traceable during its life cycle from the paddock to the restaurant.</p> <Link to="/casestudies/casestudy-1/">More..</Link>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12 col-md-6 mb-4">
                            <div className="case">
                                <div className="card-content">
                                    <Link to="/casestudies/managing-invoicing-and-early-payments-in-the-telco-industry/"><h4>Managing Invoicing and Early Payments in the Telco Industry</h4></Link>
                                    <p className="truncate">A company accepting orders from Leading Australian Telco’s'
                                    for the laying of NBN cabling across Australia, faced many challenges in the early payment process. Lilardia Capital's Reverse Factoring
                  solution was deployed, seamlessly managing invoicin, while also fully automating the early payment process.</p><Link to="/casestudies/casestudy-2/">More..</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }


}

export default casestudies;
